@import '../style/main';

.widget {
    position: relative;
    z-index: 0;
    background-color: $widget-background;
    border-radius: $radius;
    overflow: hidden;
    box-shadow: 0 3px 7px 0 rgba(#000, 0.09);
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: box-shadow 0.15s ease-out;

    &.editWidget {
        box-shadow: 0px 7px 10px 0px rgba(#000, 0.09);
    }

    .widgetHeader {
        height: 50px;
        border-bottom: 1px solid $border;
        padding-left: $widget-gutter;
        padding-right: $widget-gutter;
        z-index: 1;
        flex: 0 0 50px;

        position: relative;
        background-color: $widget-background;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .widgetTitle {
            @extend %h4;
            flex-grow: 1;
        }

        .widgetSettingsButton {
            width: 31px;
            height: 31px;
            margin-right: -10px;
            border-radius: 6px;
            border: 0;
            color: $button-icon;
            padding: 8px;

            cursor: pointer;

            &:hover {
                color: #000;
            }

            &.active {
                background-color: #000;
                color: #fff;
            }
        }

        .changingSettings {
            background-color: black;

            &:hover {
                color: #fff
            }
        }

        .widgetDeleteButton {
            width: 31px;
            height: 31px;
            margin-right: -10px;
            border-radius: 6px;
            border: 0;
            color: $button-icon;
            padding: 8px;

            cursor: pointer;

            &:hover {
                color: $red;
            }

            &.active {
                background-color: #000;
                color: #fff;
            }
        }
    }

    .widgetContent {
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1 1 auto;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 6px;
            padding-right: 10px;
            background-color: $selection;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 999px;
            background-color: $subdued;
        }
    }

    .settingsPanel{
        margin: 5px 15px;
        font-weight: lighter;

        label{
            font-size: 1.2em;
        }

        div{
            margin: 10px 10px 10px 0;
        }


        /*
            Select Styling Below
        */

        .selectContainer{
            display:block;
            overflow:hidden;

            label {
                margin-right: 10px;
            }

            select{
                border-radius: 3px;
                border: 1px solid lightgrey;
                padding: 5px;
                outline:none;
            }

        }

        /*
            Checkbox Styling Below
            Most of this is taken from https://css-tricks.com/the-checkbox-hack/ with some changes.
        */


        /* Base for label styling */
        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
            position: absolute;
            left: 0;
            opacity: 0.01;
        }
        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label {
            position: relative;
            padding-left: 2.3em;
            line-height: 1.7;
            cursor: pointer;
        }


        /* checkbox aspect */
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 1.4em;
            height: 1.4em;
            //border: 1px solid #aaa;
            background: rgb(240, 240, 240);
            transition: all .275s;
        }

        /* checked mark aspect */
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
            content: '✔';
            position: absolute;
            top: .525em;
            left: .18em;
            font-size: 1.5em;
            color: black;
            line-height: 0;
            -webkit-transition: all .2s;
            transition: all .2s;
        }

        /* checked mark aspect changes */
        [type="checkbox"]:not(:checked) + label:after {
            opacity: 0;
            -webkit-transform: scale(0) rotate(45deg);
            transform: scale(0) rotate(45deg);
        }

        [type="checkbox"]:checked + label:after {
            opacity: 1;
            -webkit-transform: scale(1) rotate(0);
            transform: scale(1) rotate(0);
        }
    }
}