html {
  margin: 0 !important;
  font-size: 10px;
  line-height: 1.5;
  box-sizing: border-box;
  padding-bottom: 0 !important;
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;

  font-size: 10px;
  font-family: $font-family-body;
  font-weight: 400;
  line-height: inherit;
  color: $font-color;
  background-color: $background;
}

button {
  background: none;
  @include no-select;
}

.Icon {
  fill: currentColor;
}