@import "../style/main";

.startingSoonWidget{
  a {
    color: $blue;
  }

  .startingSoonContent {
    padding: $widget-gutter;
  }

  .startingSoonExtra{
    color: $subdued;
  }

}

