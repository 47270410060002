.editModeHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .editMode {
        display: flex;
        flex-direction: row;
        padding-left: 5px;
        span {
            line-height: 28px;
            padding-right: 5px;
        }
    }
}

.frontPage {
    max-height: 100vh;
}

.gridContainer {
    height: calc(100% - 22px);
}