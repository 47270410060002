
// =============================================================================
// MISC
// =============================================================================

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}



// =============================================================================
// MIXINS
// =============================================================================


@mixin object-fit($fit: cover, $position: null){
  -o-object-fit: $fit;
     object-fit: $fit;
  @if $position {
      -o-object-position: $position;
         object-position: $position;
      font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
      font-family: 'object-fit: #{$fit}';
  }
}

@mixin mq($viewports, $constraint1: max, $constraint2: width) {
      // Set default variables
      $min: min-#{$constraint2};
      $max: max-#{$constraint2};

      @if( type-of( $viewports ) == number ) {
        $width: $viewports - 1px;
        @if ($constraint1 == 'min') {
          $width: $viewports;
        }
        @media screen and (#{$constraint1}-#{$constraint2}: $width) {
          @content;
        }
      // Breakpoint variable is passed
      } @else if ( type-of( $viewports ) == string ) {
          @if ( map-has-key( $breakpoints, $viewports ) ) {
              $breakpoint: map-get( $breakpoints, $viewports );
              $width: $breakpoint - 1px;
              @if ($constraint1 == 'min') {
                $width: $breakpoint;
              }
              @media screen and (#{$constraint1}-#{$constraint2}: $width) {
                  @content;
              }
          }
      } @else {
          @media screen and ($min: nth($viewports, 1)) and ($max: nth($viewports, 2)-1px) {
              @content;
          }
      }
}

@mixin fill{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    pointer-events: none; // Makes sure users cant pull the image

    @include object-fit;
}

@mixin appearance($value: none){
    -moz-appearance:$value;
    -webkit-appearance: $value;
    appearance: $value;
}

@mixin no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
                outline: none;
}


@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url('fonts/' + quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin visually-hidden() {
  position: absolute !important;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin hide-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  &::-webkit-scrollbar { /* WebKit */
    background: transparent;
    width: 0;
    height: 0;
  }
}