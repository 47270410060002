@import '../style/main';

.widgetDrawerContainer {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 300px;

    transition: transform 0.4s;
    transition-timing-function: ease-out;

    &.widgetDrawerActive {
        transform: translate(75%, 0);
    }

    &.widgetDrawerInactive {
        transform: translate(112.5%, 0);
    }

    &.widgetDrawerActive:hover {
        transform: translate(0%, 0);
    }
}

.widgetDrawer {
    margin-right: 25px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding-top: 1px;
    height: calc(100% - 50px);
    border-radius: $radius;
    background-color: $widget-background;
    z-index: 10000;



    &::before {
        position: absolute;
        top: 25px;
        right: 25px;
        bottom: 25px;
        width: calc(100% - 25px);
        display: block;
        border-radius: $radius;
        z-index: 9999;
        pointer-events: none;
        content:" ";
        box-shadow: 0px 9px 30px 0px rgba(#000, 0.15);
        opacity: 0;
        transition: opacity 0.4s;
    }
    &.widgetDrawerActive::before {
        opacity: 0.5;
    }
    &.widgetDrawerActive:hover::before {
        opacity: 1;
    }

    button {
        border: none;
        font-size: 25px;
        cursor: pointer;
    }
}


.widgetDrawerElement {
    margin: 20px;
    height: 50px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-radius: $radius;

    &:hover {
        background-color: rgb(243, 243, 243);
    }
}

.widgetDrawerElementPreviewIcon {
    width: 50px;
    height: 100%;
    background-color: $red;
    color: white;
    font-size: 15px;
    border-radius: $radius;
    text-align: center;
    line-height: 50px;
}

.widgetDrawerElementTitle{
    padding-left: 12px;
    line-height: 50px;
    flex-grow: 1;
    font-size: 12px;
}