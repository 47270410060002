%heading {
    letter-spacing: 0;
    font-weight: 400;
    font-family: $font-family-header, sans-serif;
    line-height: 1.2;
}

a {
    text-decoration: none;
    color: inherit;
    @include mq(small, min) {
        &:hover, &:active, &:focus {
            text-decoration: underline;
        }
    }
}

.rte {
    line-height: 1.7;
    ul {
        list-style: initial;
        padding-left: 20px;
    }
    p:not(:last-child) {
        margin-bottom: 1em;
    }
}

p {
    line-height: 1.75;
    font-size: 1.2rem;

    &.subdued {
        color: $subdued;
    }
}

strong,
b{
    font-weight: bold;
}

small {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.1rem;
    display: block;
}

em,
i{
    font-style: italic;
}

h1,
%h1,
.h1 {
    @extend %heading;
    margin-bottom: 20px;
    font-size: 1.6rem;
}

h2,
%h2,
.h2 {
    @extend %heading;
    font-size: 1rem;
}

h3,
%h3,
.h3 {
    @extend %heading;
    font-size: 1rem;
}

h4,
%h4,
.h4 {
    @extend %heading;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
}

h5,
%h5,
.h5 {
    @extend %heading;
    font-size: 1rem;
}

h6,
%h6,
.h6 {
    @extend %heading;
    font-size: 1rem;
}