//*// FONT VARIABLES //*//
$font-family-body: 'Gotham', sans-serif;
$font-family-header: $font-family-body;


//*// FONT COLORS //*//
$background: #F1F1F1;
$widget-background: #ffffff;

$font-color: #000;

$subdued: #A7AAAB;

$selection: #F6F8F9;

$red: #EC6464;
$green: #88D88D;
$blue: #5171DA;
$border: #F3F5F6;

$button-icon: #D9DEE0;


//*// SPACING //*//

$grid-gutter: 40px;

$widget-gutter: 20px;
$widget-content-gutter: $widget-gutter/2;

$radius: 8px;


//*// BREAKPOINTS //*//
$breakpoints: (
    small: 512px,
    medium: 768px,
    large: 1024px,
    content: 1200px,
    laptop: 1440px,
    desktop: 1920px,
    retina: 2880px
);