@import "../style/main";

.announcementHeader {
    color: $subdued;
}

.announcement {
    margin: $widget-gutter;
    position: relative;
    cursor: pointer;

    .announcementContent {
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        // TODO: this shows 4 line on firefox for me, but only n-1 on other browsers? What?!?!
        -webkit-line-clamp: 4; /* number of lines to show */
        -webkit-box-orient: vertical;
    }

    &:hover:before {
        content: "";
        position: absolute;
        left: -$widget-content-gutter;
        right: -$widget-content-gutter;
        top: -$widget-content-gutter;
        bottom: -$widget-content-gutter;
        border-radius: $radius;
        background-color: $selection;
        z-index: -1;
    }
}



/*


.announcementWidget {
    height: 100%;
}

.announcementWidget > .widgetContent {
    height: calc(100% - 75px);
    overflow-y: auto;
}

.announcement {
    margin: 0 2px 0 2px;
    padding: 5px 8px 5px 8px;
    display: flex;
    flex-direction: row;
    background-color: rgb(246, 217, 170);
    border-radius: 3px;
    margin-bottom: 7px;
    font-size: 10px;
    box-shadow: 0 5px 6px -6px #777;
    overflow: hidden;
}

.announcementAuthor {
    max-width: calc(100% - 25px);
    text-align: left;
    flex-grow: 1;
}

.announcementDate {
    text-align: right;
}

.announcementTitle {
    padding-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    max-height: 50%;
}

.announcementContent {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
}

@keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }

.popup-content {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 7px rgba(0,0,0,0.15);
    width: 150px;
    height: 100px;
    -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}*/
