@import '../style/main';

.handinWidget {

    .widgetContent {
        padding: $widget-gutter;
    }


    .divider {
        width: calc(100% + #{$widget-gutter});
        height: 1px;
        background-color: $border;
        margin: $widget-gutter -#{$widget-gutter};
    }

    .handin {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        cursor: pointer;

        &:hover:before {
            content: "";
            position: absolute;
            left: -$widget-content-gutter;
            right: -$widget-content-gutter;
            top: -$widget-content-gutter;
            bottom: -$widget-content-gutter;
            border-radius: $radius;
            background-color: $selection;
            z-index: -1;
        }

        & + .handin {
            margin-top: $widget-gutter;
        }

        .handinName + .courseName {
            margin-top: 2px;
        }

        .handinName,
        .courseName {
            line-height: 14px;
        }

        > .textLeft {
            overflow: hidden;
        }

        .courseName {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .daysDue {
            margin-left: $widget-content-gutter;
            font-weight: 500;
            white-space: nowrap;
        }

        &.dueSoon .daysDue {
            color: $red;
        }

        .doneStatus {
            margin-left: $widget-content-gutter;
            width: 21px;
            height: 21px;
            min-width: 21px;
            min-height: 21px;
            border-radius: 999px;
            position: relative;

            .Icon {
                color: #fff;
                width: 13px;
                height: 13px;
                left: 4px;
                top: 4px;
                position: absolute;
            }

            &.accepted {
                background-color: $green;
            }
            &.failed {
                background-color: $red;
            }
        }
    }
}