.scheduleWidget {
    height: 100%;
}

.scheduleWidget > .widgetContent {
    height: 100%;
}

.scheduleListGroupHeader {
    padding-top: 7px;
    padding-left: 5px;
    font-size: 12px;
}

.scheduleEntry {
    margin: 0 2px 0 2px;
    padding: 5px 8px 5px 8px;
    display: flex;
    flex-direction: row;
    background-color: rgb(246, 217, 170);
    border-radius: 3px;
    margin-bottom: 7px;
    font-size: 10px;
    box-shadow: 0 5px 6px -6px #777;
    overflow: hidden;
}

.scheduleEntryLeft {
    max-width: calc(100% - 25px);
    text-align: left;
    flex-grow: 1;
}

.scheduleEntryRight {
    text-align: right;
}

.scheduleEntryCourse {
    padding-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    max-height: 50%;
}

.scheduleEntryActivity {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
}

@keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }

.popup-content {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 7px rgba(0,0,0,0.15);
    width: 150px;
    height: 100px;
    -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.scheduleWeekGrid {
    $dateHeight: 32px;
    $rowHeight: calc((100% - #{$dateHeight}) / 9);
    $colWidth: calc(100% / 5);
    display: grid;
    height: calc(100% - 5px);
    grid-template-rows: $dateHeight $rowHeight $rowHeight $rowHeight $rowHeight $rowHeight $rowHeight $rowHeight $rowHeight $rowHeight;
    grid-template-columns: $colWidth $colWidth $colWidth $colWidth $colWidth;
}

.scheduleWeekGrid .scheduleEntry {
    height: calc(100% - 15px);
}

.scheduleWeekLine {
    color: rgba(0, 0, 0, 0);
    height: 100%;
    border-bottom: 1px solid #000;
    opacity: 0.5;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
}

.scheduleWeekDate {
    padding-top: 6px;
    text-align: center;
    background-color: rgb(243, 252, 255);
    font-size: 13px;
    opacity: 0.6;
    margin-bottom: 2px;
    box-shadow:
        inset 0px -11px 8px -13px #CCC;
}